import styled from "styled-components";

export const TABLET_BREAKPOINT = "900px";
export const PHONE_BREAKPOINT = "750px";

export const CloseButton = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 15px;

  &:hover {
    background: #c0c0c0;
  }
`;
