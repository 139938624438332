import React from "react";
import styled from "styled-components";
import { PHONE_BREAKPOINT } from "../utils/styled";

const StyledMessageBubble = styled.div`
  background: #eee;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 15px;
  width: 70%;

  p {
    margin: 0.5em 0;
  }

  .date-time {
    float: right;
    color: grey;
    font-size: 12px;
  }

  .author {
    font-style: italic;
    font-size: 12px;
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    width: 100%;
  }
`;

const MessageBubble = ({ message }) => {
  return (
    <StyledMessageBubble>
      <>
        <p className="date-time">{message.dateTime}</p>
        <p className="author">{message.author} says</p>
      </>

      <p className="contents">{message.contents}</p>
    </StyledMessageBubble>
  );
};

export default MessageBubble;
