import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import { makeAxiosRequest } from "../utils/api";
import MessageBubble from "./MessageBubble";
import { updateMessage } from "../utils/updateMessage";
import { toast } from "react-toastify";
import MessageForm from "./MessageForm";
import { Link } from "react-router-dom";
import { useRsvps } from "../Context/rsvpProvider";
import RefreshButton from "./RefreshButton";
import { PHONE_BREAKPOINT } from "../utils/styled";
import { Button } from "@mui/material";
import { buttonCss } from "./PartyDetails";

const MessageContent = styled.div`
  h1 {
    margin: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button.new-msg-btn {
    margin-left: 10px;
    @media (max-width: ${PHONE_BREAKPOINT}) {
      ${buttonCss}
    }
  }
`;
const MessagesPortal = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 1;
  overflow-y: scroll;
  padding: 10px;
`;

const defaultMessage = {
  contents: "",
  author: "",
};

const defaultErrors = {
  author: false,
  contents: false,
};

const Messages = () => {
  const { messages, setMessagesAndLocalState } = useRsvps();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState(defaultMessage);
  const [errors, setErrors] = useState(defaultErrors);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const validFields = (field) => {
    if (!inputMessage[field] && !errors[field]) {
      setErrors({ ...errors, [field]: true });
    } else if (inputMessage[field] && errors[field]) {
      setErrors({ ...errors, [field]: false });
    }
  };

  const getMessages = useCallback(() => {
    makeAxiosRequest("GET", "Messages").then((res) => {
      setMessagesAndLocalState(res.data);
    });
  }, [setMessagesAndLocalState]);

  const loudGetMessages = () => {
    getMessages();

    toast.promise(makeAxiosRequest, {
      pending: "Hang tight",
      success: "messages updated!",
      error: "Uh oh there's been an issue 🤯",
    });
  };

  useEffect(() => {
    if (inputMessage.author && inputMessage.contents && !loading) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [inputMessage, loading]);

  useEffect(() => {
    if (messages.length === 0) {
      getMessages();
    }
  }, [getMessages, messages.length]);

  const handleMessageChange = (e) => {
    const { name, value } = e.target;
    setInputMessage({
      ...inputMessage,
      [name]: value,
    });
  };

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (!allowSubmit) {
      return;
    }
    setLoading(true);
    makeAxiosRequest("POST", "Messages", updateMessage(inputMessage))
      .then((res) => {
        if (res.status === 200) {
          setInputMessage(defaultMessage);
          getMessages();
          setLoading(false);
          setModalOpen(false);
        }
      })
      .catch((err) => console.error("error adding message", err));

    toast.promise(makeAxiosRequest, {
      pending: "Hang tight",
      success: "message sent!",
      error: "Uh oh there's been an issue 🤯",
    });
  };
  return (
    <MessageContent>
      <h1>
        Messages
        <RefreshButton onClick={loudGetMessages} />
      </h1>
      <Link to="/" style={{ marginBottom: "15px" }}>
        <Button variant="contained">back to details</Button>
      </Link>
      <Button
        className="new-msg-btn"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        new message
      </Button>

      <MessagesPortal>
        <MessageContainer>
          {messages.map((message) => {
            return <MessageBubble message={message} key={message.createdAt} />;
          })}
        </MessageContainer>
        <MessageForm
          allowSubmit={allowSubmit}
          handleMessageChange={handleMessageChange}
          handleMessageSubmit={handleMessageSubmit}
          errors={errors}
          inputMessage={inputMessage}
          validFields={validFields}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </MessagesPortal>
    </MessageContent>
  );
};

export default Messages;
