import React from "react";
import { RefreshCcw as Refresh } from "@styled-icons/feather/RefreshCcw";
import styled from "styled-components";

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const RefreshButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} title="refresh data">
      <Refresh size={20} />
    </Button>
  );
};

export default RefreshButton;
