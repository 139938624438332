import React from "react";
import { TextField } from "@mui/material";
import TinySubmitButton from "./TinySubmitButton";
import styled from "styled-components";
import { PHONE_BREAKPOINT } from "../utils/styled";
import { Modal, Box } from "@mui/material";
import { boxStyle } from "./RSVPForm";

const MessageFormStyled = styled.form`
  background: white;
  padding: 10px 2em;
  display: grid;
  grid-template-columns: auto 50px;
  grid-template-rows: 50px 50px;
  margin: 1em 0;
  width: 100%;
  gap: 5px;
  overflow-y: scroll;
  button {
    grid-column: 2;
    grid-row: 2;
    justify-self: flex-end;
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    position: relative;
    bottom: 0;
    padding: 0;
  }
`;

const MessageForm = ({
  allowSubmit,
  errors,
  handleMessageChange,
  handleMessageSubmit,
  inputMessage,
  validFields,
  modalOpen,
  setModalOpen,
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-messages"
      aria-describedby="modal-messages"
    >
      <Box sx={boxStyle}>
        <MessageFormStyled onSubmit={handleMessageSubmit}>
          <TextField
            error={errors.author}
            id="author"
            label="Name"
            variant="standard"
            required
            size="small"
            onChange={handleMessageChange}
            value={inputMessage.author}
            name="author"
            onBlur={() => validFields("author")}
            sx={{ gridRow: 2 }}
          />
          <TextField
            error={errors.contents}
            id="author"
            label="Your message"
            variant="standard"
            required
            size="small"
            onChange={handleMessageChange}
            value={inputMessage.contents}
            name="contents"
            onBlur={() => validFields("contents")}
            sx={{ gridColumn: "1" }}
          />
          <TinySubmitButton
            role="submit"
            onClick={handleMessageSubmit}
            disabled={!allowSubmit}
          />
        </MessageFormStyled>
      </Box>
    </Modal>
  );
};

export default MessageForm;
