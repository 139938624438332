import React from "react";
import { Button } from "@mui/material";
import sandwichPhoto from "../images/breakfastsandwich.png";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { PHONE_BREAKPOINT, TABLET_BREAKPOINT } from "../utils/styled";

export const buttonCss = css`
  position: fixed;
  bottom: 1.5em;
`;

const PartyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 15px 0;
  }

  p {
    margin: 0.5em 0;
  }
  .mobile {
    display: none;
  }

  h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin: 0;
  }

  img {
    width: 30%;
  }

  @media (max-width: ${TABLET_BREAKPOINT}) {
    img {
      width: 60%;
      margin: 1em auto;
    }

    .flex {
      flex-direction: column;
      margin: 0;
      align-items: start;
    }
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    margin-bottom: 2em;

    button.mobile {
      display: block;
    }

    h1 {
      flex-direction: column;
      align-items: start;
      gap: 0;

      button.rsvp-btn {
        ${buttonCss}
        right: 1.5em;
      }

      & + button {
        ${buttonCss}
        left: 1.5em;
      }
    }
  }
`;

const PartyDetails = ({ setModalOpen, setOpenRsvpPanel, openRsvpPanel }) => {
  return (
    <>
      <PartyDetailsWrapper>
        <h1>
          Brakfast Sandwich{" "}
          <Link to="/messages">
            <Button variant="contained">to message board </Button>
          </Link>
          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            className="rsvp-btn"
          >
            RSVP!!!
          </Button>
        </h1>
        <Button
          variant="contained"
          className="mobile"
          onClick={() =>
            setOpenRsvpPanel((prevOpenRsvpPanel) => !prevOpenRsvpPanel)
          }
        >
          {openRsvpPanel ? "Close RSVPs" : "See RSVPs"}
        </Button>
        <div className="details">
          <p>
            God has called upon me to make eggs in a sheet pan and turn them
            into breakfast sandwiches and I'm not gonna eat them all myself!!!
          </p>
          <div className="flex">
            <div>
              <div>
                <strong>who:</strong> you
              </div>
              <div>
                <strong>what:</strong> i (amy) will be making sandwiches
              </div>
              <div>
                <strong>when:</strong> sunday july 28 at like 10:30am
              </div>
              <div>
                <strong>where:</strong> 1334 west carmen ave #3
              </div>
            </div>

            <img src={sandwichPhoto} alt="brakfast sanwich" />
          </div>

          <p>
            {" "}
            I will have stuff to make mimosas or something but feel free to byob
            or bring any brunch foods! I might make potatoes.
          </p>
        </div>

        <h2>about</h2>
        <p>
          I made this site because i hate the website "partiful" well i'm
          actually here to say <strong>partiful wins</strong>. making this
          sucked i had to sit inside for so long even when it was sunny and at
          the end of this endeavor have i realized this was a massive waste of
          my time on this planet.
        </p>
        <p>
          if anything on this dumbass website breaks (likely) you can just text
          me if you're coming or no
        </p>
      </PartyDetailsWrapper>
    </>
  );
};

export default PartyDetails;
