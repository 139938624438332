import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Popover,
  FormHelperText,
} from "@mui/material";
import { Close } from "@styled-icons/evil/Close";
import { CloseButton } from "../utils/styled";

import styled from "styled-components";

export const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "80vh",
  p: 4,
  margin: "5px",
  overflowY: "scroll",
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledForm = styled.fieldset`
  border: none;

  form {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  legend {
    font-weight: 600;
    font-size: 24;
    margin-bottom: 1em;
  }
`;

const RSVPForm = ({
  attendeeInfo,
  handleAttendeeChange,
  handleSubmit,
  modalOpen,
  closeModal,
  setAttendeeInfo,
}) => {
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({
    name: false,
    attending: false,
    secretPhrase: false,
  });
  const [openHelpText, setOpenHelpText] = useState(false);

  const validFields = (field) => {
    if (!attendeeInfo[field] && !errors[field]) {
      setErrors({ ...errors, [field]: true });
    } else if (attendeeInfo[field] && errors[field]) {
      setErrors({ ...errors, [field]: false });
    }
  };
  useEffect(() => {
    if (
      attendeeInfo.name &&
      attendeeInfo.attending &&
      attendeeInfo.secretPhrase
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [attendeeInfo]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <CloseButton onClick={closeModal}>
          <Close size={30} />
        </CloseButton>
        <Formik initialValues={attendeeInfo} onSubmit={handleSubmit}>
          <StyledForm>
            <legend> RSVP to breakfast sandwich </legend>
            <form>
              <TextField
                error={errors.name}
                id="name"
                label="Name"
                variant="outlined"
                required
                size="small"
                onChange={handleAttendeeChange}
                value={attendeeInfo.name}
                name="name"
                onBlur={() => validFields("name")}
              />
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Attending?*
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  error={errors.attending}
                  defaultValue=""
                  name="radio-buttons-group"
                  onBlur={() => validFields("attending")}
                  row
                  required
                >
                  <FormControlLabel
                    color="error"
                    error={errors.attending}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    name="attending"
                    onChange={handleAttendeeChange}
                    checked={attendeeInfo.attending === "yes"}
                  />
                  <FormControlLabel
                    error={errors.attending}
                    control={<Radio />}
                    label="Maybe"
                    name="attending"
                    value="maybe"
                    onChange={handleAttendeeChange}
                    checked={attendeeInfo.attending === "maybe"}
                  />
                  <FormControlLabel
                    error={errors.attending}
                    control={<Radio />}
                    name="attending"
                    value="no"
                    onChange={handleAttendeeChange}
                    checked={attendeeInfo.attending === "no"}
                    label="no :("
                  />
                </RadioGroup>
                {errors.attending && (
                  <FormHelperText sx={{ color: "#e74c3c" }}>
                    Choose an RSVP reponse
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Plus ones?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={attendeeInfo.plusOnes}
                  label="Plus ones"
                  onChange={handleAttendeeChange}
                  size="small"
                  name="plusOnes"
                  disabled={!(attendeeInfo.attending !== "no")}
                >
                  <MenuItem value={0} name="plusOnes">
                    0
                  </MenuItem>
                  <MenuItem value={1} name="plusOnes">
                    1
                  </MenuItem>
                  <MenuItem value={2} name="plusOnes">
                    2
                  </MenuItem>
                  <MenuItem value={3} name="plusOnes">
                    3
                  </MenuItem>
                </Select>
              </FormControl>
              <div>
                <TextField
                  label="Secret phrase"
                  onBlur={() => validFields("secretPhrase")}
                  error={errors.secretPhrase}
                  variant="outlined"
                  required
                  size="small"
                  id="secretPhrase"
                  onChange={handleAttendeeChange}
                  value={attendeeInfo.secretPhrase}
                  name="secretPhrase"
                />
                <FormHelperText
                  sx={{ cursor: "pointer", color: "#0288d1" }}
                  onClick={(e) => {
                    setOpenHelpText(true);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  What is this?
                </FormHelperText>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  anchorEl={anchorEl}
                  id="popover-helper"
                  open={openHelpText}
                  onClose={() => setOpenHelpText(false)}
                >
                  <Alert severity="info" sx={{ width: "400px" }}>
                    Use this secret phrase to edit your response later!! Don't
                    put anything sensitive here this site is extremely
                    vulnerable to attackers i'm not kidding
                  </Alert>
                </Popover>
              </div>
              <FormControl>
                <FormLabel id="radio-buttons-subscribe">
                  Do you want me to text you a reminder the day before?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-subscribe"
                  name="radio-buttons-subscribe"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes I'd love a text"
                    name="subscribe"
                    onChange={handleAttendeeChange}
                    checked={attendeeInfo.subscribe === "true"}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No thank you I will remember!"
                    name="subscribe"
                    onChange={handleAttendeeChange}
                    checked={attendeeInfo.subscribe === "false"}
                  />
                </RadioGroup>
              </FormControl>

              {Object.values(errors).includes(true) && (
                <Alert severity="error">
                  Please fill out required fields above before submitting
                </Alert>
              )}
              <ButtonContainer>
                <Button variant="outlined" onClick={closeModal}>
                  discard changes
                </Button>
                <Button
                  variant="contained"
                  disabled={!allowSubmit}
                  role="submit"
                  onClick={handleSubmit}
                >
                  submit
                </Button>
              </ButtonContainer>
            </form>
          </StyledForm>
        </Formik>
      </Box>
    </Modal>
  );
};

export default RSVPForm;
