import React, { useState } from "react";
import styled from "styled-components";
import { Check } from "@styled-icons/boxicons-regular/Check";
import { QuestionMark } from "@styled-icons/boxicons-regular/QuestionMark";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { Edit } from "@styled-icons/boxicons-solid/Edit";
import { useRsvps } from "../Context/rsvpProvider";
import { Popover, FormHelperText, TextField } from "@mui/material";
import TinySubmitButton from "./TinySubmitButton";
import { PHONE_BREAKPOINT } from "../utils/styled";

const CircleBadge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${PHONE_BREAKPOINT}) {
    width: 22px;
    height: 22px;
  }
`;

const AttendeeLineContainer = styled.div`
  display: grid;
  grid-template-columns: 30px auto 30px;
  align-items: center;

  .edit {
    cursor: pointer;
    opacity: 0;
  }
  & > span {
    grid-column: 2;
  }

  &:hover {
    .edit {
      opacity: 100;
    }
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    gap: 10px;
    font-size: 18px;
    .edit {
      opacity: 100;
    }
  }
`;

const PopupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`;

const InputStyling = styled.div`
  display: grid;
  grid-template-columns: auto 50px;

  button {
    justify-self: center;
  }
`;

const TransparentButton = styled.button`
  border: none;
  background: transparent;
`;

const AttendingLine = ({ rsvp }) => {
  const { setActiveEditingRsvp } = useRsvps();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [secretInput, setSecretInput] = useState("");
  const [error, setError] = useState(false);

  const validateSecretPhrase = (e) => {
    e.preventDefault();
    if (secretInput === rsvp.secretPhrase) {
      setActiveEditingRsvp(rsvp);
      setPopup(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  const getIconObject = (attending) => {
    switch (attending) {
      case "yes":
        return {
          color: "#1876D2",
          Icon: <Check size={20} />,
        };
      case "maybe":
        return {
          color: "#FFAA46",
          Icon: <QuestionMark size={20} />,
        };
      case "no":
        return {
          color: "#B91A1A",
          Icon: <CloseOutline size={20} />,
        };
      default:
        return {};
    }
  };

  const iconDetails = getIconObject(rsvp.attending);

  return (
    <AttendeeLineContainer>
      <CircleBadge
        aria-label={rsvp.attending}
        label={rsvp.attending}
        color={iconDetails.color}
      >
        {iconDetails.Icon}
      </CircleBadge>
      <span>
        {rsvp.name}{" "}
        {Number(rsvp.plusOnes) > 0 && (
          <span className="grey">(+{rsvp.plusOnes})</span>
        )}
      </span>
      <TransparentButton
        className="edit"
        onClick={(e) => {
          setPopup(true);
          setAnchorEl(e.currentTarget);
          setSecretInput("");
        }}
      >
        <Edit size={20} />
      </TransparentButton>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        id="popover-helper"
        open={popup}
        onClose={() => {
          setError(false);
          setPopup(false);
        }}
      >
        <PopupContentContainer>
          <FormHelperText>
            {!error
              ? "Enter your secret phrase to update your RSVP"
              : "that was NOT right"}
          </FormHelperText>
          <form onSubmit={validateSecretPhrase} method="post">
            <InputStyling>
              <TextField
                label="Secret phrase"
                error={error}
                variant="outlined"
                size="small"
                id="secretPhrase-popup"
                onChange={(e) => setSecretInput(e.target.value)}
                value={secretInput}
                name="secretPhrase"
              />
              <TinySubmitButton onClick={validateSecretPhrase} />
            </InputStyling>
          </form>
        </PopupContentContainer>
      </Popover>
    </AttendeeLineContainer>
  );
};

export default AttendingLine;
