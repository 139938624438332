import axios from "axios";
import mockData, { mockMessageData } from "../mockData";

const csvUrl =
  "https://sheet.best/api/sheets/2177b6f1-e855-4057-9be7-3a01f099fc0b";

export const getMockData = async (endpoint) => {
  const data = endpoint === "Messages" ? mockMessageData : mockData;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!!data) {
        resolve({
          status: 200,
          data,
        });
      } else {
        reject({
          type: "Error ❌",
          message: "error",
        });
      }
    }, 1000);
  });
};

export async function makeAxiosRequest(method, endpoint, data, index) {
  let url = csvUrl;
  if (endpoint === "Messages") {
    url = `${csvUrl}/tabs/Messages`;
  } else if (method === "PUT") {
    url = `${csvUrl}/${index}`;
  }

  return (
    axios({ url: url, data: data, method: method })
      // return getMockData(endpoint)
      .then((response) => {
        return { data: response.data, status: response.status };
      })
      .catch((err) => console.error("error happening", err))
  );
}
