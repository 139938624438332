import { v1 as uuid } from "uuid";

export const updateAttendeeData = (attendee) => {
  const date = new Date();
  const timestamp = date.toUTCString();
  return {
    name: attendee.name,
    attending: attendee.attending,
    plusOnes: attendee.plusOnes,
    subscribe: attendee.subscribe,
    secretPhrase: attendee.secretPhrase,
    createdAt: attendee.createdAt || timestamp,
    id: attendee.id || uuid(),
  };
};
