import React, { useEffect, useState } from "react";
import RSVPForm from "./Components/RSVPForm";
import { Route, Routes } from "react-router-dom";

import { makeAxiosRequest } from "./utils/api";
import { updateAttendeeData } from "./utils/updateAttendeeData";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import styled from "styled-components";
import AttendeePanel from "./Components/AttendeesPanel";
import PartyDetails from "./Components/PartyDetails";
import { useRsvps } from "./Context/rsvpProvider";
import Messages from "./Components/Messages";
import { PHONE_BREAKPOINT, TABLET_BREAKPOINT } from "./utils/styled";

const AppBackground = styled.div`
  background: #e9e9e9;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const AppContainer = styled.div`
  display: flex;
  gap: 1em;
  height: 85vh;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2em;
  position: relative;

  @media (max-width: ${PHONE_BREAKPOINT}) {
    display: block;
    padding: 1em;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
`;

const DetailsContainer = styled.div`
  padding: 2em;
  background-color: white;
  border-radius: 8px;
  min-width: 500px;
  overflow-y: scroll;
  position: relative;

  @media (max-width: ${TABLET_BREAKPOINT}) {
    min-width: 0;
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    height: 85vh;
    padding: 1.5em;
    overflow-y: scroll;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const defaultAttendeeInfo = {
  name: "",
  attending: "",
  plusOnes: 0,
  secretPhrase: "",
  subscribe: "",
};

const App = () => {
  const {
    attendees,
    setAttendeesAndLocalState,
    activeEditingRsvp,
    setActiveEditingRsvp,
  } = useRsvps();
  const [attendeeInfo, setAttendeeInfo] = useState(defaultAttendeeInfo);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeEditingIndex, setActiveEditingIndex] = useState(null);
  const [openRsvpPanel, setOpenRsvpPanel] = useState(false);

  const activeEditing = Object.keys(activeEditingRsvp).length !== 0;

  const getRsvpData = () =>
    makeAxiosRequest("GET", "rsvp").then((res) => {
      setAttendeesAndLocalState(res.data);
    });

  const loudGetRsvpData = () => {
    getRsvpData();
    toast.promise(makeAxiosRequest, {
      pending: "Hang tight",
      success: "RSVPs updated!",
      error: "Uh oh there's been an issue 🤯",
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setActiveEditingRsvp({});
    setActiveEditingIndex(null);
    setAttendeeInfo(defaultAttendeeInfo);
  };

  const handleAttendeeChange = (e) => {
    const { name, value } = e.target;
    setAttendeeInfo({
      ...attendeeInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    makeAxiosRequest(
      activeEditing ? "PUT" : "POST",
      "RSVP",
      updateAttendeeData(attendeeInfo),
      activeEditingIndex
    )
      .then((res) => {
        if (res.status === 200) {
          closeModal();
          getRsvpData();
        }
      })
      .catch((err) => console.error("error editing data", err));

    toast.promise(makeAxiosRequest, {
      pending: "Hang tight",
      success: activeEditing ? "RSVP updated!" : "RSVP received! 👌",
      error: "Uh oh there's been an issue 🤯",
    });
  };

  useEffect(() => {
    if (activeEditing) {
      setActiveEditingIndex(
        attendees
          .map(function (e) {
            return e.id;
          })
          .indexOf(activeEditingRsvp.id)
      );
      setAttendeeInfo(activeEditingRsvp);
      setModalOpen(true);
    }
  }, [activeEditing, activeEditingRsvp, attendees]);

  useEffect(() => {
    if (attendees.length === 0) {
      getRsvpData();
    }
  });

  return (
    <AppBackground>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      ;
      <RSVPForm
        attendeeInfo={attendeeInfo}
        handleAttendeeChange={handleAttendeeChange}
        handleSubmit={handleSubmit}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <AppContainer>
        <DetailsContainer>
          <Routes>
            <Route
              path="/"
              element={
                <PartyDetails
                  setModalOpen={setModalOpen}
                  setOpenRsvpPanel={setOpenRsvpPanel}
                  openRsvpPanel={openRsvpPanel}
                />
              }
            />
            <Route path="/messages" element={<Messages />} />
          </Routes>
        </DetailsContainer>

        <AttendeePanel
          rsvps={attendees}
          getRsvpData={loudGetRsvpData}
          openRsvpsPanel={openRsvpPanel}
          setOpenRsvpPanel={setOpenRsvpPanel}
        />
      </AppContainer>
    </AppBackground>
  );
};

export default App;
