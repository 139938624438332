const storageKeyRsvp = "rsvpData";
const storageKeyMessages = "messages";

export function setAllAttendees(attendees) {
  localStorage.setItem(storageKeyRsvp, JSON.stringify(attendees));
}

export function setAllMessages(messages) {
  localStorage.setItem(storageKeyMessages, JSON.stringify(messages));
}

export function getAllAttendees() {
  const attendees = localStorage.getItem(storageKeyRsvp);
  return JSON.parse(attendees) || [];
}

export function getAllMessages() {
  const attendees = localStorage.getItem(storageKeyMessages);
  return JSON.parse(attendees) || [];
}
