import React, { createContext, useContext, useState } from "react";
import {
  getAllAttendees,
  setAllAttendees,
  getAllMessages,
  setAllMessages,
} from "./rsvpMethods";

const RsvpContext = createContext(null);

export const RSVPContextProvider = ({ children }) => {
  const [activeEditingRsvp, setActiveEditingRsvp] = useState({});
  const [attendees, setAttendees] = useState(getAllAttendees());
  const [messages, setMessages] = useState(getAllMessages());

  function setAttendeesAndLocalState(attendeeData) {
    setAttendees(attendeeData);
    setAllAttendees(attendeeData);
  }

  function setMessagesAndLocalState(messageData) {
    setMessages(messageData);
    setAllMessages(messageData);
  }

  return (
    <RsvpContext.Provider
      value={{
        attendees,
        setAttendeesAndLocalState,
        activeEditingRsvp,
        setActiveEditingRsvp,
        setMessagesAndLocalState,
        messages,
      }}
    >
      {children}
    </RsvpContext.Provider>
  );
};

export const useRsvps = () => {
  return useContext(RsvpContext);
};
