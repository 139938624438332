import React from "react";
import { Button } from "@mui/material";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";

const TinySubmitButton = ({ onClick, disabled, role }) => {
  return (
    <Button
      role={role}
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      sx={{ minWidth: 0, padding: "5px", width: 40 }}
    >
      <ArrowRightShort size={20} />
    </Button>
  );
};

export default TinySubmitButton;
