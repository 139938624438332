import React from "react";
import styled from "styled-components";
import AttendingLine from "./AttendingLine";
import RefreshButton from "./RefreshButton";
import { CloseButton, PHONE_BREAKPOINT } from "../utils/styled";
import { Close } from "@styled-icons/evil/Close";

const AttendeePanelContainer = styled.div`
  padding: 1.8em;
  background: white;
  border-radius: 8px;
  overflow-y: scroll;
  min-width: 250px;

  h2 {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h3 {
    font-weight: 400;
  }

  .grey {
    color: #979797;
  }

  button.mobile {
    display: none;
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: ${(props) => (props.openRsvpsPanel ? 0 : "-750px")};
    width: 100%;
    transition: all 300ms ease;
    z-index: 500;
    border-radius: 10px;

    button.mobile {
      display: block;
    }
  }
`;

const AttendeesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const AttendeePanel = ({
  rsvps,
  getRsvpData,
  openRsvpsPanel,
  setOpenRsvpPanel,
}) => {
  let sortedRsvps = { yes: [], maybe: [], no: [] };
  rsvps?.forEach((x) => {
    sortedRsvps[x.attending] = [...sortedRsvps[x.attending], x];
  });
  return (
    <AttendeePanelContainer openRsvpsPanel={openRsvpsPanel}>
      <CloseButton className="mobile" onClick={() => setOpenRsvpPanel(false)}>
        <Close size={30} />{" "}
      </CloseButton>
      <h2>
        Attendees <RefreshButton onClick={getRsvpData} />
      </h2>
      <div>
        <h3>
          Going <span className="grey">({sortedRsvps.yes.length})</span>{" "}
        </h3>
        <AttendeesContainer>
          {sortedRsvps.yes.map((x) => (
            <AttendingLine rsvp={x} key={x.id} />
          ))}
          <h3>
            Maybe <span className="grey">({sortedRsvps.maybe.length})</span>{" "}
          </h3>
          {sortedRsvps.maybe.map((x) => (
            <AttendingLine rsvp={x} key={x.id} />
          ))}
          <h3>
            no 😡 <span className="grey">({sortedRsvps.no.length})</span>
          </h3>
          {sortedRsvps.no.map((x) => (
            <AttendingLine rsvp={x} key={x.id} />
          ))}
        </AttendeesContainer>
      </div>
    </AttendeePanelContainer>
  );
};

export default AttendeePanel;
